export const environment = {
  production: true,
  mode: 'prod',
  APP_REGISTRATION: {
    CLIENT_ID: 'f69bb773-2f59-4b48-a5dc-821a0d40e9a3',
  },
  BACKEND_URL: 'https://stellargpt-maz.ab-inbev.com/stellar',
  GATEWAY_URL: 'https://stellargpt-maz.ab-inbev.com/genai',
  APIM_SUBSCRIPTION_KEY: '',
  APPLICATION_ID: '05600ada-3c33-430f-bc70-cb98c4f8aa80',
  APPLICATION_LABEL: 'Stellar',
  PERSONAL_RAG_CONNECTOR_ID: 'b501a5e7-8b88-4ee0-a236-2392df5e0689',
  APP_INSIGHTS: {
    INSTRUMENTATION_KEY: '',
    CONNECTION_STRING: '',
  },
  LAYOUT: {
    PERSONAL_RAG: false,
    CONTEXT_SELECTOR: false,
  },
};
